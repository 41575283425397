import React from 'react';
import classNames from 'classnames/bind';

import styles from './StoreCell.module.scss';
import ScaleImageCell from '../misc/ScaleImageCell';
import phoneNumber from 'utility/phoneNumber';

const cx = classNames.bind(styles);

const StoreCell = ({ store, onSelect }) => {
  const { image, name, addr1, addr2, tel } = store;

  return (
    <div className={cx('store')} onClick={onSelect}>
      <div className={cx('image')}>
        <ScaleImageCell image={image.url} ratio={2 / 3} />
      </div>
      <div className={cx('name')}>{name}</div>
      <div className={cx('address')}>{addr1}<br/>{addr2}</div>
      <div className={cx('tel')}>{phoneNumber(tel)}</div>
    </div>
  );
};

export default StoreCell;