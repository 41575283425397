import React, { Component } from 'react';
import classNames from 'classnames/bind';

import styles from './Desktop.module.scss';
import phoneNumber from 'utility/phoneNumber';

const cx = classNames.bind(styles);

let markerImage;
let mapViewer;
let scrollTop;

export default class Map extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.store.code !== nextProps.store.code) {
      return {
        store: nextProps.store,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      store: props.store,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    const mapContainer = document.getElementById('map'); // 지도를 표시할 div
    const mapOption = {
      center: new window.daum.maps.LatLng(0, 0), // 지도의 중심좌표
      level: 3, // 지도의 확대 레벨
    };

    const imageSrc = require('assets/images/misc/marker.png'); // 마커이미지의 주소입니다.
    const imageSize = new window.daum.maps.Size(50, 74.5); // 마커이미지의 크기입니다.
    const imageOption = { offset: new window.daum.maps.Point(25, 74.5) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

    // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다.
    markerImage = new window.daum.maps.MarkerImage(imageSrc, imageSize, imageOption);
    // 지도를 생성합니다.
    mapViewer = new window.daum.maps.Map(mapContainer, mapOption);

    this.showMap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.store.code !== this.state.store.code) {
      this.showMap();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const {
      name,
      addr1,
      addr2,
      tel,
      park_info,
      time_monday,
      time_tuesday,
      time_wednesday,
      time_thursday,
      time_friday,
      time_saturday,
      time_sunday,
    } = this.state.store;

    return (
      <div id="mapWrapper" className={cx('mapWrapper')}>
        {/* 지도 */}
        <div id="map" className={cx('map')} />

        {/* 상세정보 */}
        <div className={cx('detail')}>
          <div className={cx('name')}>{name}</div>
          <div className={cx('address')}>
            {addr1} {addr2}
          </div>
          <div className={cx('tel')}>{phoneNumber(tel)}</div>
          <div className={cx('parkInfo')}>{park_info}</div>

          {/* 운영시간표 */}
          {time_monday && (
            <div className={cx('opentime')}>
              <div className={cx('day')}>
                <div className={cx('dayName')}>월요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_monday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>화요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_tuesday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>수요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_wednesday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>목요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_thursday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>금요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_friday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>토요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_saturday)}</div>
              </div>
              <div className={cx('day')}>
                <div className={cx('dayName')}>일요일</div>
                <div className={cx('time')}>{this.getTimeReplace(time_sunday)}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  getTimeReplace = time => {
    if (time === undefined || time === null || time === '') {
      return '-';
    }

    if (time.split('-')[0] === '휴점') {
      return '휴점';
    }

    return (
      <span>
        {time.split('-')[0]}
        <br />
        ~
        <br />
        {time.split('-')[1]}
      </span>
    );
  };

  handleScroll = () => {
    const mapWrapper = document.getElementById('mapWrapper');
    const storeList = document.getElementById('storeList');

    const clientHeight = document.documentElement.clientHeight;
    mapWrapper.style.top = '0px';

    if (mapWrapper.getBoundingClientRect().top < 0) {
      scrollTop = document.documentElement.scrollTop + mapWrapper.getBoundingClientRect().top;
      mapWrapper.classList.add(cx('fixed'));
    } else if (document.documentElement.scrollTop < scrollTop) {
      scrollTop = 0;
      mapWrapper.classList.remove(cx('fixed'));
    } else if (clientHeight > storeList.getBoundingClientRect().height + storeList.getBoundingClientRect().top) {
      const bottomHeight =
        clientHeight - (storeList.getBoundingClientRect().height + storeList.getBoundingClientRect().top);
      mapWrapper.style.top = -1 * bottomHeight + 'px';
    }
  };

  showMap = () => {
    const { latitude, longitude } = this.state.store;

    // 마커가 표시될 위치입니다.
    const markerPosition = new window.daum.maps.LatLng(latitude, longitude);

    // 마커를 생성합니다.
    const marker = new window.daum.maps.Marker({
      position: markerPosition,
      image: markerImage, // 마커이미지 설정
    });

    // 마커가 지도 위에 표시되도록 설정합니다.
    marker.setMap(mapViewer);

    const moveTo = new window.daum.maps.LatLng(latitude - 0.001, longitude);
    mapViewer.panTo(moveTo);
  };
}
