import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './index.module.scss';
import { storeActions } from '../../actions';
import DesktopMap from './Map/Desktop';
import MobileMap from './Map/Mobile';
import StoreCell from './StoreCell';

const cx = classNames.bind(styles);

class Store extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.store;

    if (!isProcessing && !failure && data.length > 0) {
      const loadedStoreList = nextProps.store.data;

      if (prevState.storeList.length < loadedStoreList.length) {
        return {
          storeList: loadedStoreList,
          selectedStore: loadedStoreList[0],
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      storeList: [],
      selectedStore: null,
      showMobileMap: false,
    };
  }

  componentDidMount() {
    if (this.props.store.data.length === 0) {
      this.props.actions.storeActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.store;
    return !isProcessing && !failure && data.length > 0;
  }

  render() {
    const { keyword, storeList, selectedStore, showMobileMap } = this.state;

    return (
      <div>
        <div className={cx('contentWrapper')}>
          <Container fluid>
            <Row>
              <Col xs={12} lg={6}>
                <div id="storeList" className={cx('storeList')}>
                  <Container>
                    <Row>
                      <Col xs={12} xl={8}>
                        <div className={cx('searchPanel')}>
                          <div className={cx('fieldSet')}>
                            <div className={cx('searchIcon')}>
                              <Ionicon icon="md-search" fontSize="17px" />
                            </div>
                            <input type="text" name="keyword" value={keyword} placeholder="매장명, 주소, 전화번호" onChange={this.handleChange} className={cx('field')} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {storeList.map((store, i) => {
                        const { name, addr1, addr2, tel } = store;
                        const isMatch = name.includes(keyword) || addr1.concat(addr2).includes(keyword) || tel.includes(keyword);

                        if (isMatch) {
                          return (
                            <Col xs={6} md={4} lg={6} xl={4} key={i}>
                              <StoreCell store={store} onSelect={() => this.selectStore(store)} />
                            </Col>
                          ); 
                        } else {
                          return ('');
                        }
                      })}
                    </Row>
                  </Container>
                </div>
              </Col>
              {selectedStore !== null && (
                <Col xs={12} lg={6} style={{ paddingTop: 72 }}>
                  <Visible lg xl>
                    <DesktopMap store={selectedStore} />
                  </Visible>
                </Col>
              )}
            </Row>
          </Container>
        </div>

        {selectedStore !== null && (
          <Hidden lg xl>
            {showMobileMap && (
              <div className={cx('mobileMap')}>
                <Ionicon icon="ios-close" fontSize="80px" className={cx('mapClose')} onClick={this.closeMobileMap} />
                <MobileMap store={selectedStore} />
              </div>
            )}
          </Hidden>
        )}
      </div>
    );
  }

  handleChange = (event) => {
    this.setState({ keyword: event.target.value });
  }

  selectStore = (store) => {
    this.setState({
      selectedStore: store,
      showMobileMap: true,
    });
  }

  closeMobileMap = () => {
    this.setState({
      showMobileMap: false,
    });
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { store } = state;
  return {
    store,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      storeActions: bindActionCreators(storeActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Store);