const locationNumbers = [
  '02',
  '051',
  '053',
  '032',
  '062',
  '042',
  '052',
  '044',
  '031',
  '033',
  '043',
  '041',
  '063',
  '061',
  '054',
  '055',
  '064'
];

export default (tel) => {
  try {
    if (tel === '' || tel === null || tel === undefined) {
      return tel;
    }
  
    let locationNumber;
    locationNumbers.some((number) => {
      const match = tel.startsWith(number);
      if (match) {
        locationNumber = number;
      }

      return match;
    });

    let numberWithoutLocationNumber = tel.substring(locationNumber.length);

    switch (numberWithoutLocationNumber.length) {
      case 7:
      numberWithoutLocationNumber = `${numberWithoutLocationNumber.substring(0, 3)}-${numberWithoutLocationNumber.substring(3)}`;
      break;

      case 8:
      numberWithoutLocationNumber = `${numberWithoutLocationNumber.substring(0, 4)}-${numberWithoutLocationNumber.substring(4)}`;
      break;

      default:
      break;
    }

    return `${locationNumber}-${numberWithoutLocationNumber}`;

  }
  catch (e) {
    return tel;
  }
}