import React from 'react';

import Store from 'components/Store';
import PageTitleBar from 'components/misc/PageTitleBar';

const StorePage = () => (
  <div>
    <PageTitleBar title="Find a store" backgroundImage={require('../assets/images/banner/store.jpg')} />
    <Store />
  </div>
);

export default StorePage;
